section[id*="image-focus"] {
  overflow-x: clip;
}
section[id*="image-focus"] .image-block .sqs-block-content {
  transition: transform 0.2s ease;
}
section[id*="image-focus"] .image-block-outer-wrapper {
  filter: blur(var(--blur, 3px));
  transition: filter 0.3s ease;
}
section[id*="image-focus"] .image-block-outer-wrapper:has(.sqs-image:hover) {
  filter: blur(0px);
}
@media (hover: none) {
  section[id*="image-focus"] .image-block-outer-wrapper {
    filter: blur(0px);
  }
}
